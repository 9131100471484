import {
  DatabaseOutlined,
  FileTextOutlined,
  ImportOutlined,
  LogoutOutlined,
  PieChartOutlined,
  PlusOutlined,
  PushpinOutlined,
  TeamOutlined,
} from '@ant-design/icons';
import {Layout, Menu} from 'antd';
import {ItemType} from 'antd/lib/menu/hooks/useItems';
import React, {CSSProperties, ReactNode, useEffect, useState} from 'react';
import {useDispatch, useSelector} from 'react-redux';
import {RouteComponentProps} from 'react-router';
import {Link, NavLink, withRouter} from 'react-router-dom';
import type {Flags} from '../../../src/feature-flags';
import {getEnabledFlags} from '../../../src/feature-flags';
import {I18nSimpleKey} from '../../../src/i18n/i18n';
import {getEnabledLayers} from '../../../src/layers/map-layers';
import {useApis} from '../apis/ApisContext';
import {State} from '../redux';
import {logout} from '../redux/logout';
import {useAdminInfo} from '../util/admin-util';

const {Sider} = Layout;

const MENU_OPTIONS: [string, I18nSimpleKey, Flags | null][] = [
  ['policies', 'Policies', null],
  ['farms', 'Farms', null],
  ['farm_harvests', 'FarmHarvests', null],
  ['field_harvests', 'FieldHarvests', null],
  ['scoring', 'FieldScoring', 'fieldScoring'],
  ['samples', 'Samples', null],
  ['reports', 'VisitReport', 'visitMode'],
  ['claims', 'Claims', 'claims'],
  ['regions', 'Regions', null],
];

const siderStyle: CSSProperties = {backgroundColor: 'white', overflow: 'auto', height: 'calc(100vh - 48px)'};

const getEnabledWebLayers = (state: State) => getEnabledLayers(state, 'web');

export const Sidebar: React.FC<RouteComponentProps> = ({history}) => {
  const apis = useApis();
  const mapLayers = useSelector(getEnabledWebLayers);
  const [collapsed, setCollapsed] = useState<boolean>(window.innerWidth <= 1024);
  const [location, setLocation] = useState<any>(history.location);
  const enabledFlags = useSelector(getEnabledFlags);
  const isAdmin = useAdminInfo();
  const dispatch = useDispatch<any>();
  useEffect(() => {
    return history.listen((location: any) => {
      setLocation(location);
    });
  }, [history]);
  const pathname = (location?.pathname ?? '').split('/').slice(0, 3).join('/');

  const mapItems = [...mapLayers].map(layer => ({
    key: `/map/${layer}`,
    style: {height: 'auto', lineHeight: 'normal'},
    label: (
      <NavLink to={`/map/${layer}${location.search}`} className="nav-text">
        <span style={{whiteSpace: 'normal'}}>{apis.t(layer)}</span>
      </NavLink>
    ),
  }));

  const listItems: ItemType[] = MENU_OPTIONS.filter(
    ([_, __, requiredFlag]) => !requiredFlag || enabledFlags.has(requiredFlag),
  ).map(([l, t, _]) => renderNavLink(`/list/${l}`, null, apis.t(t)));

  const items: ItemType[] = [
    {key: '/', icon: <PieChartOutlined />, label: <Link to="/">{apis.t('Dashboard')}</Link>},
    {key: '/map', icon: <PushpinOutlined />, label: apis.t('Map'), children: mapItems},
    {key: '/list', icon: <DatabaseOutlined />, label: apis.t('List'), children: listItems},
  ];
  if (!enabledFlags.has('hideEditingFunctionality')) {
    items.push({
      key: '/add/farms',
      icon: <PlusOutlined />,
      label: <Link to="/add/farms">{apis.t('AddFarm') + '/' + apis.t('Fields')}</Link>,
    });
  }
  if (enabledFlags.has('import') && !enabledFlags.has('hideEditingFunctionality')) {
    items.push(renderNavLink('/import/data', <ImportOutlined />, apis.t('ImportData')));
  }

  if (!!apis.store.getState().dbMeta.userGroups.find(x => x.user_group == 'terrexpert') && enabledFlags.has('import')) {
    items.push(renderNavLink('/import/terrexpert', <ImportOutlined />, 'Import Terrexpert'));
  }

  if (enabledFlags.has('portfolioReport')) {
    items.push(renderNavLink('/portfolio-report', <FileTextOutlined />, apis.t('PortfolioReport')));
  }
  items.push(renderNavLink('/farm-report', <FileTextOutlined />, apis.t('ExpertReport')));
  if (isAdmin.isUserAdmin) {
    items.push(renderNavLink('/gt-admin/users', <TeamOutlined />, apis.t('UserAdministration')));
  }
  items.push({
    key: '/signOut',
    label: (
      <a href="#" onClick={() => dispatch(logout(apis))}>
        <LogoutOutlined />
        <span>{apis.t('Logout')}</span>
      </a>
    ),
  });

  return (
    <Sider
      className="no-print thin-mobile-sidebar"
      collapsible
      collapsed={collapsed}
      onCollapse={setCollapsed}
      style={siderStyle}>
      <Link to="/">
        <img
          alt="logo"
          style={{padding: '10px', width: '100%'}}
          src={collapsed ? '/logo.png' : '/greentriangle-logo.png'}
        />
      </Link>
      <Menu mode="inline" selectedKeys={pathname ? [pathname] : []} items={items} />
    </Sider>
  );
};

function renderNavLink(to: string, icon: ReactNode, label: string): ItemType {
  return {
    key: to,
    icon,
    label: (
      <NavLink to={to} className="nav-text">
        <span>{label}</span>
      </NavLink>
    ),
  };
}

export default withRouter(Sidebar);
