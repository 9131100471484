import {TelepacHarvestContents} from '../gt-pack/telepac';
import {FarmReportValues, ReportSignature, VisitReportOptions} from '../report/report-types';
import {isBool, isNum} from '../validator-constraints';
import {CoverageType, LossCause, VisitType} from './interfaces';

// TODO(kristjan): Move to SQL and consequently to interfaces.ts
export const Gender = ['male', 'female', 'other'] as const;
export type Gender = (typeof Gender)[number];

export const InsuredStatus = ['verified', 'unverified'] as const;
export type InsuredStatus = (typeof InsuredStatus)[number];

export class FarmCustomColumns {
  constructor(x: null | any) {}
}

// Insured entity can be either a company or person.
export interface LegalEntity {
  // Common properties.
  // External id is a unique identification number (e.g. tax number).
  // In Brazil, it's either CNPJ for companies or CPF for persons.
  external_id: string | null;
  // We only need to collect street with number, zip code and country. State, city, municipality can be deduced.
  street_with_number: string | null;
  zip_code: string | null;
  country: string | null;

  email: string | null;
  phone: string | null;

  // Properties for person.
  first_name: string | null;
  last_name: string | null;
  gender: Gender | null;

  // Properties for company.
  trade_name: string | null;
  corporate_name: string | null;

  inception_date: string | null;
  insured_status: InsuredStatus | null;
}

export interface Broker {
  entity: LegalEntity;
  leader: boolean | null;
}

export interface Beneficiary {
  entity: LegalEntity;
  percent: number | null;
}

export interface MgaPolicyCustomColumns {
  // Culture code from the Brazilian central bank.
  culture_code: string | null;
  // SUSEP code for the product. SUSEP is a department in the Brazilian government that regulates insurance in the country.
  product_code: string | null;

  years_of_agriculture: number | null;
  climatic_event_past_5_years: boolean | null;
  crop_already_planted: boolean | null;
  preexisting_policy_for_same_area: boolean | null;
  certified_or_registered_seeds: boolean | null;
  drainage_problems: boolean | null;
  flood_or_waterlogging_past_5_years: boolean | null;
  aware_of_zoagro_mapa: boolean | null;
  subsidy_policy_id: string | null;
}

// We allow everything to be null to avoid unnecessarily pollution of the custom columns.
export class PolicyCustomColumns {
  insured: LegalEntity | null;
  valid_from: string | null;
  valid_to: string | null;
  coverage_type: CoverageType | null;
  brokers: Broker[] | null;
  beneficiaries: Beneficiary[] | null;
  mga: MgaPolicyCustomColumns | null;

  constructor(x: Partial<PolicyCustomColumns> | null) {
    this.insured = x?.insured ?? null;
    this.valid_from = x?.valid_from ?? null;
    this.valid_to = x?.valid_to ?? null;
    this.coverage_type = x?.coverage_type ?? null;
    this.brokers = x?.brokers ?? null;
    this.beneficiaries = x?.beneficiaries ?? null;
    this.mga = x?.mga ?? null;
  }
}

export class FieldCustomColumns {
  constructor(x: null | any) {}
}

export type AffectedZone = {
  // TODO(seb): Remove optionality after Q2 2024.
  inseeCode?: string;
  identifiers: string[];
  affected: boolean;
  area: number;
  lossCauses: LossCause[];
  lossLabels: string[];
};

export class EtlCustomColumns {
  franchises: string[] | null;
  cropCode: string;
  cropInfoCode: string;
  cngraCode: string;

  constructor(x: null | any) {
    this.franchises = getStrArray(x, 'franchises');
    this.cropCode = x?.cropCode ?? null;
    this.cropInfoCode = x?.cropInfoCode ?? null;
    this.cngraCode = x?.cngraCode ?? null;
  }
}

export class HarvestCustomColumns {
  grpmFranchises: string[] | null;
  grpmPastYields: [null | number, null | number, null | number, null | number, null | number] | null;
  grpmAvgYield: number | null;
  grpmUpdatedYields: [null | number, null | number, null | number, null | number, null | number] | null;
  grpmAffectedZones: AffectedZone[] | null;
  grpmCulture: string | null;
  grpmCropDetails: {
    Espece: string | null;
    Variete: string | null;
    Saisonnalite: string | null;
    DetailRegional: string | null;
    Couleur: string | null;
    ModeDeProduction: string | null;
    Destination: string | null;
  } | null;

  etl: EtlCustomColumns | null;

  telepacData: TelepacHarvestContents | null;

  constructor(x: null | any) {
    Object.assign(this, x);
    this.grpmFranchises = getStrArray(x, 'grpmFranchises');
    this.grpmPastYields = x?.grpmPastYields ?? null;
    if (this.grpmPastYields?.length != 5 || this.grpmPastYields.some(x => !isNum(x))) {
      this.grpmPastYields = null;
    }
    this.grpmUpdatedYields = x?.grpmUpdatedYields ?? null;
    if (this.grpmUpdatedYields?.length != 5 || this.grpmUpdatedYields.some(x => !isNum(x))) {
      this.grpmUpdatedYields = null;
    }
    this.grpmAvgYield = getNum(x, 'grpmAvgYield');
    this.grpmAffectedZones = x?.grpmAffectedZones ?? null;
    if (this.grpmAffectedZones) {
      if (
        !this.grpmAffectedZones.every(y => getStrArray(y, 'identifiers') != null) ||
        !this.grpmAffectedZones.every(y => getStrArray(y, 'lossCauses') != null) ||
        !this.grpmAffectedZones.every(y => getStrArray(y, 'lossLabels') != null) ||
        !this.grpmAffectedZones.every(y => isBool(y?.affected)) ||
        !this.grpmAffectedZones.every(y => getNum(y, 'area') != null)
      ) {
        this.grpmAffectedZones = null;
      }
    }
    this.telepacData = x?.telepacData ?? null;
    this.grpmCulture = getStr(x, 'grpmCulture');
    this.grpmCropDetails = x?.grpmCropDetails
      ? {
          Espece: getStr(x.grpmCropDetails, 'Espece'),
          Variete: getStr(x.grpmCropDetails, 'Variete'),
          Saisonnalite: getStr(x.grpmCropDetails, 'Saisonnalite'),
          DetailRegional: getStr(x.grpmCropDetails, 'DetailRegional'),
          Couleur: getStr(x.grpmCropDetails, 'Couleur'),
          ModeDeProduction: getStr(x.grpmCropDetails, 'ModeDeProduction'),
          Destination: getStr(x.grpmCropDetails, 'Destination'),
        }
      : null;

    this.etl = x?.etl ? new EtlCustomColumns(x.etl) : null;
  }
}

function getStrArray(x: null | undefined | Record<string, any>, prop: string): null | string[] {
  const val = x?.[prop];
  if (val instanceof Array && val.every(x => typeof x == 'string')) {
    return val;
  }

  return null;
}

function getNum(x: null | undefined | Record<string, any>, prop: string): null | number {
  const val = x?.[prop];
  if (typeof val == 'number') {
    return val;
  }

  return null;
}

function getStr(x: null | undefined | Record<string, any>, prop: string): null | string {
  const val = x?.[prop];
  if (typeof val == 'string') {
    return val;
  }
  return null;
}

export const GrpmVisitAction = [
  'preinspection-none',
  'validation-without-review',
  'validation-with-review',
  'monitoring-without-review',
  'monitoring-without-review-provisioning',
  'monitoring-with-review',
  'monitoring-with-review-provisioning',
  'claim-some',
  'claim-all',
  'claim-delivery',
] as const;
export type GrpmVisitAction = (typeof GrpmVisitAction)[number];

export const GrpmVisitTypeToActionMapping: Record<VisitType, GrpmVisitAction[]> = {
  'preinspection-visit': ['preinspection-none'],
  'validation-visit': ['validation-without-review', 'validation-with-review'],
  'monitoring-visit': [
    'monitoring-without-review',
    'monitoring-without-review-provisioning',
    'monitoring-with-review',
    'monitoring-with-review-provisioning',
  ],
  'claims-visit': ['claim-some', 'claim-all', 'claim-delivery'],
};

export class VisitCustomColumnsGrpm {
  visitAction: GrpmVisitAction | null;
  inseeShapes: Record<string, string>;

  constructor(x: Partial<VisitCustomColumnsGrpm> | null) {
    this.inseeShapes = x?.inseeShapes ?? {};
    this.visitAction = x?.visitAction ?? null;
  }
}

export class VisitCustomColumns {
  grpm: null | VisitCustomColumnsGrpm;
  // The values used to generate the final (signed) report.
  signedFarmReportValues?: FarmReportValues;
  // The report options used to generate the final (signed) report.
  signedVisitReportOptions?: VisitReportOptions;
  signedSignatures?: Omit<ReportSignature, 'signature'>[];
  // The app version used when signing the report.
  signedOnVersion?: string;

  constructor(x: Partial<VisitCustomColumns> | null) {
    this.grpm = x?.grpm ? new VisitCustomColumnsGrpm(x.grpm) : null;
    this.signedFarmReportValues = x?.signedFarmReportValues;
    this.signedVisitReportOptions = x?.signedVisitReportOptions;
    this.signedSignatures = x?.signedSignatures;
    this.signedOnVersion = x?.signedOnVersion;
  }

  // Some custom_columns should not be duplicated when creating a duplicate visit. This
  // method ensures that all users of this class can rely on the same duplication logic.
  duplicate(): VisitCustomColumns {
    return new VisitCustomColumns({
      grpm: this.grpm,
    });
  }
}

export class ClaimCommentGrpm {
  issued_on: string;
  text: string;

  constructor(x: null | any) {
    this.issued_on = x?.issued_on;
    this.text = x?.text;
  }
}

export class ClaimCustomColumnsGrpm {
  visitAction: GrpmVisitAction | null;
  inseeShapes: Record<string, string>;
  comments: ClaimCommentGrpm[];

  constructor(x: Partial<ClaimCustomColumnsGrpm> | null) {
    this.inseeShapes = x?.inseeShapes ?? {};
    this.visitAction = x?.visitAction ?? null;
    this.comments = x?.comments ?? [];
  }
}

export class ClaimCustomColumns {
  grpm: null | ClaimCustomColumnsGrpm;
  // The values used to generate the final (signed) report.
  signedFarmReportValues?: FarmReportValues;
  // The report options used to generate the final (signed) report.
  signedVisitReportOptions?: VisitReportOptions;
  signedSignatures?: Omit<ReportSignature, 'signature'>[];
  // The app version used when signing the report.
  signedOnVersion?: string;

  constructor(x: Partial<ClaimCustomColumns> | null) {
    this.grpm = x?.grpm ? new ClaimCustomColumnsGrpm(x.grpm) : null;
    this.signedFarmReportValues = x?.signedFarmReportValues;
    this.signedVisitReportOptions = x?.signedVisitReportOptions;
    this.signedSignatures = x?.signedSignatures;
    this.signedOnVersion = x?.signedOnVersion;
  }

  // Some custom_columns should not be duplicated when creating a duplicate visit. This
  // method ensures that all users of this class can rely on the same duplication logic.
  duplicate(): ClaimCustomColumns {
    return new ClaimCustomColumns({
      grpm: this.grpm,
    });
  }
}
