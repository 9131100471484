import {Col, Row} from 'antd';
import React, {FC, useCallback} from 'react';
import {useSelector} from 'react-redux';
import {useFormy} from '../../../src/Formy/hooks';
import {brasileiraRuralMgaHiddenName} from '../../../src/constants/group-consts';
import {Flags, getEnabledFlags} from '../../../src/feature-flags';
import {SubsidyConfiguration, subsidyConfigRequestType} from '../../../src/models/subsidy-configuration';
import {getPostgrestQueryParams} from '../../../src/util/postgrest-query';
import {FormyDateRange} from '../Formy/FormyDateRange';
import {Label} from '../Formy/FormyLabel';
import {FormySubmit} from '../Formy/FormySubmit';
import {FormyTextStr} from '../Formy/FormyText';
import {useApis} from '../apis/ApisContext';

// Subsidy config is specific to Brazilian market, and we want to show it only if the user has subsidyConfig flag
// and is part of some Brazil user group.
export function showSubsidyConfig(flags: Set<Flags>): boolean {
  return flags.has('subsidyConfig') && flags.has('hasSomeBrazilUserGroup');
}

const marginBottom10 = {marginBottom: '10px'};
const marginLeft10 = {marginLeft: '10px'};

const subsidyRequestParams = getPostgrestQueryParams({
  and: [
    {column: 'user_group', operator: 'eq', value: brasileiraRuralMgaHiddenName},
    {column: 'request_type', operator: 'eq', value: subsidyConfigRequestType},
    {column: 'status', operator: 'eq', value: 'ok'},
  ],
});

export const SubsidyConfig: FC = () => {
  const {t, authedFetcher, store} = useApis();
  const flags = useSelector(getEnabledFlags);
  const getSubsidyConfig = useCallback(async () => {
    return await authedFetcher({
      method: 'GET',
      path: 'api/request',
      params: subsidyRequestParams,
    });
  }, [authedFetcher]);
  const formy = useFormy(
    'new',
    async () => {
      const config = await getSubsidyConfig();
      return {
        claim_processing_date_from: config[0]?.request.claim_processing_date_from,
        claim_processing_date_to: config[0]?.request.claim_processing_date_to,
        claim_processing_per_minute: config[0]?.request.claim_processing_per_minute,
        policy_processing_date_from: config[0]?.request.policy_processing_date_from,
        policy_processing_date_to: config[0]?.request.policy_processing_date_to,
        policy_processing_per_minute: config[0]?.request.policy_processing_per_minute,
        quote_processing_date_from: config[0]?.request.quote_processing_date_from,
        quote_processing_date_to: config[0]?.request.quote_processing_date_to,
        quote_processing_per_minute: config[0]?.request.quote_processing_per_minute,
      };
    },
    t,
    async (subsidyConfiguration: SubsidyConfiguration) => {
      // Re-fetch config in case it changed in the meantime.
      const config = await getSubsidyConfig();
      if (config.length) {
        await authedFetcher({
          method: 'PATCH',
          path: 'api/request',
          params: subsidyRequestParams,
          json_body: {request: subsidyConfiguration},
        });
      } else {
        await authedFetcher({
          method: 'POST',
          path: 'api/request',
          json_body: {
            user_group: brasileiraRuralMgaHiddenName,
            request_type: subsidyConfigRequestType,
            request: subsidyConfiguration,
            status: 'ok',
          },
        });
      }
    },
    () => {
      return {};
    },
    null,
  );

  if (!formy || !showSubsidyConfig(flags)) {
    return null;
  }
  return (
    <>
      <Row style={marginBottom10}>
        <Col span={8}>
          <Label>{t('QuoteProcessingStartEnd')}</Label>
          <FormyDateRange
            formy={formy}
            useTime={true}
            range_from_field={'quote_processing_date_from'}
            range_to_field={'quote_processing_date_to'}
          />
        </Col>
        <Col span={4} style={marginLeft10}>
          <Label>{t('MaxSubsidiesPerMinute')}</Label>
          <FormyTextStr field={'quote_processing_per_minute'} formy={formy} />
        </Col>
      </Row>
      <Row style={marginBottom10}>
        <Col span={8}>
          <Label>{t('PolicyProcessingStartEnd')}</Label>
          <FormyDateRange
            formy={formy}
            useTime={true}
            range_from_field={'policy_processing_date_from'}
            range_to_field={'policy_processing_date_to'}
          />
        </Col>
        <Col span={4} style={marginLeft10}>
          <Label>{t('MaxSubsidiesPerMinute')}</Label>
          <FormyTextStr field={'policy_processing_per_minute'} formy={formy} />
        </Col>
      </Row>
      <Row style={marginBottom10}>
        <Col span={8}>
          <Label>{t('ClaimProcessingStartEnd')}</Label>
          <FormyDateRange
            formy={formy}
            useTime={true}
            range_from_field={'claim_processing_date_from'}
            range_to_field={'claim_processing_date_to'}
          />
        </Col>
        <Col span={4} style={marginLeft10}>
          <Label>{t('MaxSubsidiesPerMinute')}</Label>
          <FormyTextStr field={'claim_processing_per_minute'} formy={formy} />
        </Col>
      </Row>
      <Row>
        <Col>
          <FormySubmit label="Save" formy={formy} />
        </Col>
      </Row>
    </>
  );
};
