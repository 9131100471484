import {DatePicker} from 'antd';
import moment from 'moment';
import React from 'react';
import {FormyComponent} from '../../../src/Formy/FormyComponent';
import {FormyTextBaseProps} from '../../../src/Formy/FormyTextBase';

export class FormyDatePicker<F extends {[P in Fk]: null | string}, Fk extends keyof F> extends FormyComponent<
  F,
  Fk,
  FormyTextBaseProps<F, Fk>
> {
  render() {
    return (
      <DatePicker
        data-testid={`FormyDatePicker-${String(this.props.field)}`}
        placeholder={this.props.formy.t(this.props.label)}
        value={this.value ? moment(this.value) : null}
        className={'formy-item-style'}
        disabled={this.mode === 'view'}
        onChange={(date, dateString) => this.handleChange(dateString as any)}
      />
    );
  }
}
