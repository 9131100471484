import {ClockI} from '../Clock';
import {fastDeepEqual} from '../fast-deep-equal';
import {LngLat, getBoundingBox} from '../geo';
import {getUuid} from '../get-uuid';
import {ClaimData, FarmData, PolicyData} from '../models/data';
import {
  Claim,
  ClaimDamage,
  ClaimStatus,
  Farm,
  Field,
  Harvest,
  Policy,
  Visit,
  VisitCandidate,
} from '../models/interfaces';
import {TableName} from '../models/serialization';
import {AreaValue, OmitDbColumns, Uuid} from '../models/types';
import {isSubsetOf, unique} from '../util/arr-util';
import {clearNullsInPlace, isObjEmpty} from '../util/obj-util';
import {ImportedClaim, ImportedData, getImportedFarmData, getImportedHarvestData} from './gt-pack';
import {StateOrm} from './stateOrm';

export function clearSame<T extends {custom_columns: any}>(update: Partial<T>, current: Partial<T>) {
  for (const k in update) {
    if (k == 'custom_columns') {
      const updatedCustomColumns = update.custom_columns,
        curCustomColumns = current.custom_columns;
      if (typeof updatedCustomColumns == 'object' && isObjEmpty(updatedCustomColumns) && curCustomColumns == null) {
        delete update.custom_columns;
      }
    } else if (fastDeepEqual(update[k], current[k])) {
      delete update[k];
    }
  }
}

async function findPolicyById(stateOrm: StateOrm, policy_id: string) {
  return (
    (
      await stateOrm.fetchEntitiesBy('policy', {
        column: 'policy_id',
        operator: 'eq',
        value: policy_id,
      })
    )[0] ?? null
  );
}

export async function _saveImportedData(
  clock: ClockI,
  stateOrm: StateOrm,
  data: ImportedData,
  updateExistingFarms: boolean,
): Promise<{entity_type: TableName; entity_id: string}[]> {
  const farms: OmitDbColumns<Farm>[] = [];
  const policies: OmitDbColumns<Policy>[] = [];
  const fields: OmitDbColumns<Field>[] = [];
  const visits: OmitDbColumns<Visit>[] = [];
  const claims: OmitDbColumns<Claim>[] = [];
  const claimDamages: OmitDbColumns<ClaimDamage>[] = [];
  const harvests: OmitDbColumns<Harvest>[] = [];
  for (const importedFarm of data.farms) {
    let {farm_id, user_group, editors} = importedFarm;
    let policy_id = importedFarm.policy?.policy_id ?? null;
    const importedFarmDb = farm_id ? await stateOrm.getFarmById(farm_id) : null;

    if (!farm_id) {
      farm_id = getUuid();
    }
    // TODO(savv): consider moving this part of mergeImportedFarm.
    if (!user_group) {
      if (importedFarm.farm_id) {
        user_group = importedFarmDb?.user_group ?? null;
      }
    }
    if (!user_group) {
      console.error(importedFarm);
      throw new Error('No user group for imported farm');
    }

    if (importedFarm.farm_id) {
      if (updateExistingFarms) {
        const update: Partial<FarmData> = getImportedFarmData(importedFarm);
        clearNullsInPlace(update);
        delete update.user_group;
        // We might want to allow updates to add editors, after checking that they are in the right group
        // https://github.com/greentriangle/agro/pull/2216#discussion_r1118151660
        delete update.editors;
        importedFarmDb && clearSame(update, importedFarmDb);
        if (!isObjEmpty(update)) {
          await stateOrm.updateFarm(farm_id, update);
        }
      }
    } else {
      farms.push({farm_id, ...getImportedFarmData(importedFarm)});
    }

    if (policy_id) {
      // Ensure to update the policies editors, if we are re-using an existing policy.
      const policy = await findPolicyById(stateOrm, policy_id);
      if (!policy) {
        console.error('saveImportedData error: policy not found for policy_id', policy_id);
      } else {
        policy_id = policy.policy_id;
        // automatically add editors to existing policy, less risky than for farms
        const updates: Partial<PolicyData> = {};
        const policyEditors = unique([...policy.editors, ...editors]);
        if (!isSubsetOf(new Set(policyEditors), new Set(policy.editors))) {
          updates.editors = policyEditors;
        }
        // Trigger the updates only if the imported policy contains a value to avoid overwriting properties
        // with empty values (e.g. policy_number and status must not be null in any case, and we also opted not to
        // overwrite custom_columns and comments with empty values for now).
        if (importedFarm.policy?.policy_number) {
          updates.policy_number = importedFarm.policy?.policy_number;
        }
        if (importedFarm.policy?.status) {
          updates.status = importedFarm.policy.status;
        }
        if (importedFarm.policy?.custom_columns) {
          updates.custom_columns = importedFarm.policy.custom_columns;
        }
        if (importedFarm.policy?.comments) {
          updates.comments = importedFarm.policy.comments;
        }
        if (Object.keys(updates).length > 0) {
          await stateOrm.updateEntity('policy', policy.policy_id, updates);
        }
      }
    } else if (importedFarm.policy?.policy_number) {
      policy_id = getUuid();
      policies.push({
        policy_id,
        user_group,
        editors,
        policy_number: importedFarm.policy.policy_number,
        comments: importedFarm.policy.comments,
        metadata: null,
        custom_columns: importedFarm.policy.custom_columns,
        merged_ids: null,
        status: importedFarm.policy.status ?? 'policy',
      });
    }

    const existingClaimId = importedFarm.claim?.claim_id;
    const claim_id = existingClaimId ?? (importedFarm.claim ? getUuid() : null);
    const visitedHarvestIds = new Set<string>();
    const existingClaimDamages = existingClaimId
      ? await stateOrm.fetchEntitiesBy('claim_damage', {
          column: 'claim_id',
          operator: 'eq',
          value: claim_id,
        })
      : [];
    for (const importedFarmHarvest of importedFarm.farmHarvests) {
      const harvestData = getImportedHarvestData(farm_id, null, importedFarmHarvest);
      let harvest_id;
      if (importedFarmHarvest.harvest_id) {
        harvest_id = importedFarmHarvest.harvest_id;
        clearNullsInPlace(harvestData);
        if (!isObjEmpty(harvestData)) {
          await stateOrm.updateEntity('harvest', importedFarmHarvest.harvest_id, harvestData);
        }
      } else {
        harvest_id = getUuid();
        harvests.push({...harvestData, harvest_id, policy_id});
      }

      if (claim_id && importedFarmHarvest.losses.length) {
        // This harvest has losses => it's affected. Add it to the visit's harvest_ids.
        visitedHarvestIds.add(harvest_id);

        const claimDamagesForHarvest = existingClaimDamages.filter(cd => cd.harvest_id == harvest_id!);
        if (claimDamagesForHarvest.length) {
          // Update existing claim damages for this harvest.
          for (const existingClaimDamage of claimDamagesForHarvest) {
            const update = {
              insurance_loss_estimation: importedFarmHarvest.losses,
            };
            clearNullsInPlace(update);
            if (!isObjEmpty(update)) {
              await stateOrm.updateEntity('claim_damage', existingClaimDamage.claim_damage_id, update);
            }
          }
        } else {
          // Add this harvest's losses as new claim damage.
          claimDamages.push({
            claim_damage_id: getUuid(),
            claim_id,
            harvest_id,
            insurance_loss_estimation: importedFarmHarvest.losses,
            vegetation_stage: null,
          });
        }
      }
    }

    // Drop existing claim_damages which have no matching harvest, or the harvest has no losses.
    const removedClaimDamages = existingClaimDamages.filter(cd => {
      const matchingFarmHarvest = importedFarm.farmHarvests.find(h => h.harvest_id == cd.harvest_id);
      return !matchingFarmHarvest || !matchingFarmHarvest.losses.length;
    });
    for (const removedClaimDamage of removedClaimDamages) {
      await stateOrm.deleteEntity('claim_damage', removedClaimDamage.claim_damage_id);
    }

    for (const importedField of importedFarm.fields) {
      let field_location: null | LngLat = importedField.field_location;
      if (importedField.field_shape) {
        const bbox = getBoundingBox(importedField.field_shape);
        if (bbox) {
          const {ne, sw} = bbox;
          field_location = [(ne[0] + sw[0]) / 2, (ne[1] + sw[1]) / 2];
        }
      }
      const field_id = importedField.field_id ?? getUuid();
      const field = {
        farm_id,
        external_field_id: importedField.external_field_id,
        field_location,
        field_shape: importedField.field_shape,
        field_area: importedField.field_area as AreaValue,
        user_location: null,
        comments: null,
        metadata: null,
        custom_columns: null,
        region_id: null,
        merged_ids: null,
      };
      if (importedField.field_id) {
        clearNullsInPlace(field);
        if (!isObjEmpty(field)) {
          await stateOrm.updateField(field_id, field);
        }
      } else {
        fields.push({field_id, ...field});
      }

      for (const importedHarvest of importedField.harvests) {
        const harvestData = getImportedHarvestData(farm_id, field_id, importedHarvest);
        if (importedHarvest.harvest_id) {
          clearNullsInPlace(harvestData);
          if (!isObjEmpty(harvestData)) {
            await stateOrm.updateEntity('harvest', importedHarvest.harvest_id, harvestData);
          }
        } else {
          harvests.push({...harvestData, harvest_id: getUuid(), policy_id});
        }
      }
    }

    function getImportedClaimData(farm_id: string, claim: ImportedClaim, policy_id: Uuid | null): ClaimData {
      return {
        farm_id,
        policy_id,
        harvest_year: claim.harvest_year,
        external_claim_id: claim.claim_number,
        assigned_to: claim.assigned_to.map<VisitCandidate>(assigned => ({
          email: assigned,
          updated_at: null,
          visit_candidate_status: 'accepted',
        })),
        closed_on: null,
        comments: null,
        contact_email: null,
        contact_first_name: null,
        contact_last_name: null,
        contact_phone: null,
        coverage_type: null,
        custom_columns: claim.custom_columns,
        manager_email: null,
        manager_first_name: null,
        manager_last_name: null,
        manager_phone: null,
        metadata: claim.metadata,
        status: 'adjuster-accepted' as ClaimStatus,
      };
    }

    if (importedFarm.claim) {
      if (!claim_id) {
        throw new Error(`claim_id was null despite importedFarm.visit being present`);
      }

      const claimData: ClaimData = getImportedClaimData(farm_id, importedFarm.claim, policy_id);
      if (importedFarm.claim.claim_id) {
        clearNullsInPlace(claimData);
        if (!isObjEmpty(claimData)) {
          await stateOrm.updateEntity('claim', claim_id, claimData);
        }
      } else {
        claims.push({...claimData, claim_id: claim_id});
      }

      // Only create a visit, if there is an explicit visit_id set.
      const visit_id = importedFarm.claim.visit_id;
      if (visit_id) {
        const visit: OmitDbColumns<Visit> = {
          visit_id,
          claim_id,
          sample_dates: [],
          visit_type: null,
          comments: null,
          withdrawal: null,
          withdrawal_crop_ids: null,
          signed_by_farmer: null,
          signature_crop_ids: null,
          signed_report: null,
          attachments: null,
          closed: false,
          metadata: importedFarm.claim.metadata,
          custom_columns: importedFarm.claim.custom_columns,
          closed_on: null,
          external_visit_id: importedFarm.claim.external_visit_id,
          visit_date: null,
          harvest_ids: [...visitedHarvestIds],
          visit_report_uri: null,
        };
        visits.push(visit);
      }
    }
  }

  console.info(
    'Saving',
    farms.length,
    'farms',
    policies.length,
    'policies',
    fields.length,
    'fields',
    harvests.length,
    'harvests',
    visits.length,
    'visits',
    claims.length,
    'claims',
    claimDamages.length,
    'claim_damages',
  );
  await stateOrm.insertMany(farms, policies, fields, harvests, visits, claims, claimDamages);

  await stateOrm.commit();
  return [
    ...farms.map(x => ({entity_type: 'farm' as TableName, entity_id: x.farm_id})),
    ...policies.map(x => ({entity_type: 'policy' as TableName, entity_id: x.policy_id})),
    ...fields.map(x => ({entity_type: 'field' as TableName, entity_id: x.field_id})),
    ...harvests.map(x => ({entity_type: 'harvest' as TableName, entity_id: x.harvest_id})),
    ...visits.map(x => ({entity_type: 'visit' as TableName, entity_id: x.visit_id})),
    ...claims.map(x => ({entity_type: 'claim' as TableName, entity_id: x.claim_id})),
    ...claimDamages.map(x => ({entity_type: 'claim_damage' as TableName, entity_id: x.claim_damage_id})),
  ];
}
