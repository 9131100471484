import {defaultMemoize} from 'reselect';
import {UnreachableCaseError} from 'ts-essentials';
import {I18nFunction} from '../i18n/i18n';
import {HarvestYear} from '../models/interfaces';
import {
  INTERYIELD_CROP_IDS,
  InteryieldCropIds,
  interyieldSeasonDoys,
  interyieldSeasonWeeks,
} from '../models/interyield';
import {formatDate, formatDateRange, formatMonthYear} from '../text/date';
import {addDaysToDate, parseDate, toDateStr} from '../util/date-util';
import {MapLayers} from './map-layers';

export function getSatelliteDates(today: Date) {
  let dates: string[] = [];
  for (let year = 2018; year <= today.getUTCFullYear(); ++year) {
    for (let month = 1; month <= 12; ++month) {
      if (year == today.getUTCFullYear() && month >= today.getUTCMonth() + 1) {
        // If today == '2020-05-01' this will break for year=2020, month=4.
        // If today == '2021-02-17' this will break for year=2021, month=1.
        // Note that getUTCMonth is 0-indexed, whereas we use 1-indexed months in this loop - hence the + 1 in the
        // condition above.
        break;
      }
      dates.push(`${year}-${month.toString().padStart(2, '0')}-01`);
    }
  }
  dates.push('most-recent', 'high-res-mapbox');

  return dates;
}

function getWeekday(x: Date): number {
  // Returns a python-style day of week, where Monday is 0 and Sunday is 6.
  return (x.getUTCDay() + 6) % 7;
}

function toDate(ts: Date | number): string {
  return new Date(ts).toISOString().slice(0, 10);
}

function timedeltaDays(x: number) {
  return x * 24 * 60 * 60 * 1000;
}

export function getLatestEra5CanonicalDate(today: Date): string {
  // Generates canonical date for all layers which use ERA-5 data (wind, precipitation, temperature). Canonical date
  // spans 7 days and denotes Sunday of:
  // - the previous week: if today is Tuesday or later
  // - two weeks ago: if today is Monday
  if (getWeekday(today) <= 0) {
    // Date's weekday is Monday, canonical_date is Sunday 9 days ago.
    return toDate(today.getTime() - timedeltaDays(8));
  }

  // Date's weekday is Tuesday-Sunday, canonical_date is Sunday last week.
  return toDate(today.getTime() - timedeltaDays(getWeekday(today) + 1));
}

export function getLatestSentinelCanonicalDate(now: Date): string {
  // Generate canonical date for Sentinel dataset. A single period spans 1 week, from Monday to Sunday (inclusive).
  // Canonical date denotes the end of the search interval  (i.e. Sunday of last week). Hence, the data for week between
  // 2020-05-04 and 2020-05-10, will be available from Monday 2020-05-11. The canonical_date will be 2020-05-10 and
  // spans back 7 days."""
  // Get Sunday of the previous week.
  return toDate(now.getTime() - timedeltaDays(getWeekday(now) + 1));
}

export function getLatestModisCanonicalDate(now: Date): string {
  // Generates canonical date for Modis NDVI data. Modis tiles are uploaded on the FTP every Thursday at ~1:00 AM UTC.
  // A single period spans 1 week, from Monday to Sunday (inclusive). Hence, the data for week between 2020-05-04 and
  // 2020-05-10, will be available from Thursday 2020-05-14. The canonical_date is 2020-05-10 and it spans back 7 days."""
  if (getWeekday(now) > 2) {
    // Date's weekday is Thursday-Sunday, canonical_date is Sunday last week.
    return toDate(now.getTime() - timedeltaDays(getWeekday(now) + 1));
  }

  // Date's weekday is Monday-Wednesday, canonical_date is Sunday 2 weeks ago.
  return toDate(now.getTime() - timedeltaDays(getWeekday(now) + 8));
}

type CopernicusLayers = 'surface-temperature' | 'soil-moisture';
export function getLatestSurfaceTemperatureCanonicalDate(now: Date): string {
  // Generates canonical date for Copernicus dataset surface-temperature.
  // Surface-temperature: Canonical date spans 7 days and denotes Sunday of:
  // - the previous week: if today is Tuesday or later
  // - two weeks ago: if today is Monday
  if (getWeekday(now) <= 0) {
    // Date's weekday is Monday, canonical_date is Sunday 9 days ago.
    return toDate(now.getTime() - timedeltaDays(8));
  }
  // Date's weekday is Tuesday-Sunday, canonical_date is Sunday last week.
  return toDate(now.getTime() - timedeltaDays(getWeekday(now) + 1));
}

export function getLatestSoilMoistureCanonicalDate(now: Date): string {
  // Soil-moisture: Imagery is available for 1st, 11th and 21st on each month. A single period spans 10 days.
  // Canonical date denotes the end of the search period. Hence, the period spans between canonical_date and
  // canonical_date - 10 days. Data for period between 2020-05-01 and 2020-05-11 (exclusive), will be available
  // from 2020-05-11 and the canonical date will be 2020-05-11.
  // Note: Display soil-moisture layer with a 4-day lag due to EU JRC SMI data (used in Europe only) being available
  // 4 days after the reference date. For Copernicus Soil Water Index (used for the rest of the world), we should expect
  // a 3-days lag after the reference date. Hence, keep the largest expected delay to display all countries at the same
  // time.
  const newDate = new Date(new Date(now).setDate(new Date(now).getDate() - 4));
  if (1 <= newDate.getDate() && newDate.getDate() <= 10) {
    newDate.setUTCDate(1);
  } else if (11 <= newDate.getDate() && newDate.getDate() <= 20) {
    newDate.setUTCDate(11);
  } else {
    newDate.setUTCDate(21);
  }
  return toDate(newDate);
}

export function getLatestEswdCanonicalDate(today: Date): string {
  // Generates canonical date for ESWD event layer. Canonical date spans 7 days is Sunday of:
  // - the previous week: if today is Monday
  // - the current week: if today is Tuesday or later
  // That is to ensure that the events submitted retrospectively are also included and the layer
  // is not missing any events."""
  if (getWeekday(today) == 0) {
    // Date's weekday is Monday or Tuesday, canonical_date is last Sunday.
    return toDate(today.getTime() - timedeltaDays(getWeekday(today) + 1));
  }

  // Date's weekday is Tuesday-Sunday, canonical_date is the upcoming Sunday.
  return toDate(today.getTime() - timedeltaDays(getWeekday(today) - 6));
}

export function getLatestLayerDate(today: Date | string, layer: MapLayers): null | string {
  if (typeof today == 'string') {
    const parsedDate = parseDate(today);
    if (!parsedDate) {
      return null;
    }
    today = parsedDate;
  }

  switch (layer) {
    case 'base':
      return null;
    case 'satellite': {
      const dates = getSatelliteDates(today);
      return dates[dates.length - 1];
    }
    case 'crop-mon':
      return null; // This is implemented in CropMonToolbox.getAvailableDates.
    case 'hail':
      return getLatestEswdCanonicalDate(today);
    case 'intrafield':
    case 'interfield':
      return getLatestSentinelCanonicalDate(today);
    case 'interyield':
      return null;
    case 'soil-moisture':
      return getLatestSoilMoistureCanonicalDate(today);
    case 'surface-temperature':
      return getLatestSurfaceTemperatureCanonicalDate(today);
    case 'precipitation':
    case 'rainstorm':
    case 'temperature':
    case 'wind':
      return getLatestEra5CanonicalDate(today);
    case 'vegetation':
      return getLatestModisCanonicalDate(today);
    case 'custom-a': {
      const dates = getMamdaDates(today);
      return dates[dates.length - 1] ?? null;
    }
    default:
      console.warn('getLatestLayerDate:', new UnreachableCaseError(layer));
      return null;
  }
}

const layerStartDate: {[P in MapLayers]?: number} = {
  vegetation: parseDate('2018-12-30')!.getTime(),
  'soil-moisture': parseDate('2019-01-01')!.getTime(),
  intrafield: parseDate('2018-06-24')!.getTime(),
  interfield: parseDate('2018-06-24')!.getTime(),
  temperature: parseDate('2018-12-30')!.getTime(),
  'surface-temperature': parseDate('2018-12-30')!.getTime(),
  hail: parseDate('2018-12-30')!.getTime(),
  precipitation: parseDate('2018-12-30')!.getTime(),
  rainstorm: parseDate('2018-12-30')!.getTime(),
  wind: parseDate('2018-12-30')!.getTime(),
};

function getMamdaDates(today: Date): string[] {
  const dates: string[] = [];
  let curDate = parseDate('2024-02-25')!;
  while (curDate < today) {
    if (
      // NOTE: Date.getUTCMonth returns 0 - 11, whereas getUTCDate returns 1 - 31
      (curDate.getUTCMonth() == 1 && curDate.getUTCDate() >= 24) ||
      (curDate.getUTCMonth() >= 2 && curDate.getUTCMonth() <= 5)
    ) {
      // Only add dates that are relevant for MAMDA's data collection.
      dates.push(toDateStr(curDate));
    }

    curDate = addDaysToDate(curDate, 7);
  }

  return dates;
}

// Returns that layer's available layer dates, in ascending order.
function _getLayerDates(today: Date | string, layer: MapLayers): string[] {
  if (typeof today == 'string') {
    const parsedDate = parseDate(today);
    if (!parsedDate) {
      return [];
    }
    today = parsedDate;
  }

  if (layer == 'satellite') {
    return getSatelliteDates(today);
  }

  if (layer == 'custom-a') {
    return getMamdaDates(today);
  }

  const lastDate = getLatestLayerDate(today, layer);
  if (!lastDate) {
    return [];
  }

  const dates = [];
  if (layer == 'soil-moisture') {
    dates.push(lastDate);

    let curDate = parseDate(lastDate)!;
    if (curDate.getUTCDate() == 21) {
      curDate.setUTCDate(11);
      dates.push(curDate.toISOString().slice(0, 10));
    }
    if (curDate.getUTCDate() == 11) {
      curDate.setUTCDate(1);
      dates.push(curDate.toISOString().slice(0, 10));
    }

    curDate.setUTCMonth(curDate.getUTCMonth() - 1);
    while (curDate.getTime() >= layerStartDate['soil-moisture']!) {
      curDate.setUTCDate(21);
      dates.push(curDate.toISOString().slice(0, 10));
      curDate.setUTCDate(11);
      dates.push(curDate.toISOString().slice(0, 10));
      curDate.setUTCDate(1);
      dates.push(curDate.toISOString().slice(0, 10));
      curDate.setUTCMonth(curDate.getUTCMonth() - 1);
    }
    dates.reverse();
  } else {
    const lastDateTs = parseDate(lastDate)!.getTime();
    const layerStart = layerStartDate[layer];
    if (!layerStart) {
      return [];
    }

    for (
      let curDate = new Date(layerStart);
      curDate && curDate.getTime() <= lastDateTs;
      curDate.setUTCDate(curDate.getUTCDate() + 7)
    ) {
      dates.push(curDate.toISOString().slice(0, 10));
    }
  }

  return dates;
}

// Returns a canonical_date that is valid for the given layer and is close to `curDate`. Otherwise, null.
export function getNearestLayerDate(today: Date, layer: MapLayers, curDate: null | string): null | string {
  if (curDate == null || isNaN(new Date(curDate).getTime())) {
    const dates = getLayerDates(today, layer);
    return dates[dates.length - 1] ?? null;
  }

  if (layer == 'satellite') {
    // For the satellite layer, "nearest" means the same month.
    if (curDate.match(/\d\d\d\d-\d\d-\d\d/)) {
      const newDate = curDate.slice(0, 8) + '01';
      if (!getLayerDates(today, 'satellite').includes(newDate)) {
        return null;
      }
      return newDate;
    } else {
      return null;
    }
  }

  let nearestDate = null;
  const dateDist = (a: string, b: string) => Math.abs(Date.parse(a) - Date.parse(b));
  for (const date of getLayerDates(today, layer)) {
    if (!nearestDate || dateDist(date, curDate) < dateDist(date, nearestDate)) {
      nearestDate = date;
    }
  }
  return nearestDate;
}

export const getLayerDates = defaultMemoize(_getLayerDates);

export function formatLayerCanonicalDate(t: I18nFunction, layer: MapLayers, canonical_date: string) {
  if (canonical_date == 'high-res-mapbox' || canonical_date == 'most-recent') {
    return t(canonical_date);
  }

  if (layer == 'satellite') {
    return formatMonthYear(t, canonical_date);
  }

  if (canonical_date.match(/\d\d\d\d-\d\d-\d\d/)) {
    return formatDate(t, canonical_date);
  }

  return canonical_date;
}

export function getInteryieldDate(today: Date, crop_id: string, harvest_year: HarvestYear): null | string {
  if (!INTERYIELD_CROP_IDS.includes(crop_id as InteryieldCropIds)) {
    return null;
  }

  today.setUTCDate(today.getUTCDate() - 2);
  const [startDoy, endDoy] = interyieldSeasonDoys[crop_id as InteryieldCropIds];
  let startYear = harvest_year;
  if (endDoy < startDoy) {
    startYear = String(parseInt(harvest_year) - 1) as HarvestYear;
  }

  let seasonStart = parseDate(`${startYear}-01-01`)!;
  seasonStart.setUTCDate(startDoy);
  seasonStart = parseDate(getLatestSentinelCanonicalDate(seasonStart))!;

  const millisecondsInWeek = timedeltaDays(7);
  const [startWeek, endWeek] = interyieldSeasonWeeks[crop_id as InteryieldCropIds];
  const periodStart = new Date(seasonStart.getTime() + startWeek * millisecondsInWeek);
  const periodEnd = new Date(seasonStart.getTime() + (endWeek - 1) * millisecondsInWeek);

  if (today < periodStart) {
    return null;
  }
  if (today > periodEnd) {
    // periodEnd is already set to the canonical date (Sun).
    return toDateStr(periodEnd);
  }
  return getLatestSentinelCanonicalDate(today);
}

// Returns a formatted date range DD - DD MMM YYYY, based on input layer and range end date (canonical_date)
// In case of most-recent and high-res-mapbox values for the canonical date, it returns the formatted text string for the same.
export function formatLayerCanonicalDatePeriod(
  t: I18nFunction,
  layer: MapLayers,
  canonical_date: string,
  layerDates: string[],
): string {
  if (canonical_date === 'high-res-mapbox' || canonical_date === 'most-recent') {
    return t(canonical_date);
  }

  const addDaysToCanonicalDate = (c_date: string, no_days = 1) => {
    return toDateStr(addDaysToDate(parseDate(c_date)!, no_days));
  };

  const cEndDateIndex = layerDates.indexOf(canonical_date);
  const cStartDateIndex = cEndDateIndex > 0 ? cEndDateIndex - 1 : -1;

  if (cEndDateIndex === -1 || cStartDateIndex === -1) {
    return '';
  }

  let cStartDate = addDaysToCanonicalDate(layerDates[cStartDateIndex], 1);
  let cEndDate = layerDates[cEndDateIndex];

  if (layer === 'satellite') {
    const startDate = parseDate(cEndDate)!;
    const endDate = new Date(Date.UTC(startDate.getUTCFullYear(), startDate.getUTCMonth() + 1, 0));
    cStartDate = toDateStr(startDate);
    cEndDate = toDateStr(endDate);
  }

  return formatDateRange(t, cStartDate, cEndDate);
}
