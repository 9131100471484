import {read, utils} from 'xlsx';
import {ReportableError} from '../util/err-util';

export function parseTerrexpertXlsx(buffer: ArrayBuffer): {rows: TerrexpertXlsxRow[]; errors: TerrexpertParseError[]} {
  const wb = read(buffer, {type: 'buffer'});
  const sheetNameList = wb.SheetNames;
  const headers = [
    'Réf Dossier',
    'Source',
    'Expert',
    'email Expert',
    'Date de réception',
    "Date d'ouverture",
    'Date de sinistre',
    'Compagnie',
    'Réf Compagnie',
    'Campagne',
    'Assuré',
    'Num. Portable',
    'Référence sinistre',
    'N° police',
    'Adresse',
    'CP sinistre',
    'Département',
    'Ville sinistre',
    'Date du premier RDV',
    'Date du dernier RDV',
    "Date d'expertise",
    'Nb visites',
    'Etat dossier',
    'Etat conformite',
    'Date revision',
    'Date envoi vérif surfaces',
    'Date envoi vérif donnees comptables',
    'Montant provision',
    'Montant réglé',
    'Compteur sinistre',
    'Contrat',
    'Garantie',
    'Famille culture',
    'Culture',
    'Date envoi 1ère évaluation',
    'Date rapport reconnaissance',
    'Date cloture dossier',
    'MntHTvacations',
    'MntHTKms',
    'MntHTforfait',
    'MntHTNH',
    'Montant éval. Frais non engagé',
    'Montant Pertes non garanties',
    'Montant Zone inondable',
    'Date dernière modif',
    'RDV 1',
    'RDV 2',
    'RDV 3',
    'RDV 4',
    'RDV 5',
    'RDV 6',
    'RDV 7',
    'RDV 8',
    'RDV 9',
    'RDV 10',
    'RDV 11',
    'RDV 12',
    'RDV 13',
    'RDV 14',
    'RDV 15',
    'RDV 16',
    'RDV 17',
    'RDV 18',
    'RDV 19',
    'RDV 20',
    'Montant indemnité immediate',
  ];

  const lines: string[][] = utils.sheet_to_json(wb.Sheets[sheetNameList[0]], {header: 1, raw: false, defval: ''});
  if (!lines) {
    throw new TerrexpertParseError(TerrexpertParseErrorType.NO_CSV_DATA_FOUND);
  }

  if (lines.length < 2) {
    throw new TerrexpertParseError(TerrexpertParseErrorType.NOT_ENOUGH_LINES);
  }

  if (lines[0].join(';') !== headers.join(';')) {
    throw new TerrexpertParseError(TerrexpertParseErrorType.INVALID_HEADER);
  }

  const errors: TerrexpertParseError[] = [];
  const rows: TerrexpertXlsxRow[] = [];
  for (let i = 1; i < lines.length; i++) {
    try {
      rows.push(new TerrexpertXlsxRow(i, lines[i]));
    } catch (e) {
      let error = e as TerrexpertParseError;
      error =
        error ?? ({error: TerrexpertParseErrorType.UNKNOWN, message: (e as Error).message} as TerrexpertParseError);
      error.rowNumber = i;
      errors.push(error);
    }
  }
  return {rows: rows, errors: errors};
}

export class TerrexpertXlsxRow {
  refDossier: string;
  source: string;
  expert: string;
  expertEmail: string;
  dateReception: string;
  dateOuverture: string;
  dateSinistre: string;
  compagnie: string;
  refCompagnie: string;
  campagne: string;
  assure: string;
  numPortable: string;
  referenceSinistre: string;
  numPolice: string;
  adresse: string;
  cpSinistre: string;
  departement: string;
  villeSinistre: string;
  datePremierRDV: string;
  dateDernierRDV: string;
  dateExpertise: string;
  nbVisites: string;
  etatDossier: string;
  etatConformite: string;
  dateRevision: string;
  dateEnvoiVerifSurfaces: string;
  dateEnvoiVerifDonneesComptables: string;
  montantProvision: string;
  montantRegle: string;
  compteurSinistre: string;
  contrat: string;
  garantie: string;
  familleCulture: string;
  culture: string;
  dateEnvoi1ereEvaluation: string;
  dateRapportReconnaissance: string;
  dateClotureDossier: string;
  mntHTvacations: string;
  mntHTKms: string;
  mntHTforfait: string;
  mntHTNH: string;
  montantEvalFraisNonEngage: string;
  montantPertesNonGaranties: string;
  montantZoneInondable: string;
  dateDerniereModif: string;
  rdv1: string;
  rdv2: string;
  rdv3: string;
  rdv4: string;
  rdv5: string;
  rdv6: string;
  rdv7: string;
  rdv8: string;
  rdv9: string;
  rdv10: string;
  rdv11: string;
  rdv12: string;
  rdv13: string;
  rdv14: string;
  rdv15: string;
  rdv16: string;
  rdv17: string;
  rdv18: string;
  rdv19: string;
  rdv20: string;
  montantIndemniteImmediate: string;

  // The fields below are used by the import process.
  line_id: number;
  pacificaContractNumber: string;
  pacificaDosclimNumber: string;
  farmId: string | null;
  policyId: string | null;
  claimId: string | null;
  cropId: string | null;
  lossId: string | null;
  harvestId: string | null;
  userExists: boolean;
  harvestYear: string | null;

  constructor(lineNumber: number, fields: string[]) {
    if (fields.length !== 66) {
      throw new TerrexpertParseError(TerrexpertParseErrorType.NOT_ENOUGH_FIELDS);
    }
    this.line_id = lineNumber;
    this.refDossier = fields[0];
    this.source = fields[1];
    this.expert = fields[2];
    this.expertEmail = fields[3];
    this.dateReception = fields[4];
    this.dateOuverture = fields[5];
    this.dateSinistre = fields[6];
    this.compagnie = fields[7];
    this.refCompagnie = fields[8];
    this.campagne = fields[9];
    this.assure = fields[10];
    this.numPortable = fields[11];
    this.referenceSinistre = fields[12];
    this.numPolice = fields[13];
    this.adresse = fields[14];
    this.cpSinistre = fields[15];
    this.departement = fields[16];
    this.villeSinistre = fields[17];
    this.datePremierRDV = fields[18];
    this.dateDernierRDV = fields[19];
    this.dateExpertise = fields[20];
    this.nbVisites = fields[21];
    this.etatDossier = fields[22];
    this.etatConformite = fields[23];
    this.dateRevision = fields[24];
    this.dateEnvoiVerifSurfaces = fields[25];
    this.dateEnvoiVerifDonneesComptables = fields[26];
    this.montantProvision = fields[27];
    this.montantRegle = fields[28];
    this.compteurSinistre = fields[29];
    this.contrat = fields[30];
    this.garantie = fields[31];
    this.familleCulture = fields[32];
    this.culture = fields[33];
    this.dateEnvoi1ereEvaluation = fields[34];
    this.dateRapportReconnaissance = fields[35];
    this.dateClotureDossier = fields[36];
    this.mntHTvacations = fields[37];
    this.mntHTKms = fields[38];
    this.mntHTforfait = fields[39];
    this.mntHTNH = fields[40];
    this.montantEvalFraisNonEngage = fields[41];
    this.montantPertesNonGaranties = fields[42];
    this.montantZoneInondable = fields[43];
    this.dateDerniereModif = fields[44];
    this.rdv1 = fields[45];
    this.rdv2 = fields[46];
    this.rdv3 = fields[47];
    this.rdv4 = fields[48];
    this.rdv5 = fields[49];
    this.rdv6 = fields[50];
    this.rdv7 = fields[51];
    this.rdv8 = fields[52];
    this.rdv9 = fields[53];
    this.rdv10 = fields[54];
    this.rdv11 = fields[55];
    this.rdv12 = fields[56];
    this.rdv13 = fields[57];
    this.rdv14 = fields[58];
    this.rdv15 = fields[59];
    this.rdv16 = fields[60];
    this.rdv17 = fields[61];
    this.rdv18 = fields[62];
    this.rdv19 = fields[63];
    this.rdv20 = fields[64];
    this.montantIndemniteImmediate = fields[65];

    if (!this.expertEmail.toLowerCase().match(/^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/)) {
      throw new TerrexpertParseError(TerrexpertParseErrorType.INVALID_EMAIL);
    }

    if (!this.numPolice.match(/^\d{15}$/)) {
      throw new TerrexpertParseError(TerrexpertParseErrorType.INVALID_POLICY_NUMBER);
    }

    this.pacificaContractNumber = this.contrat;
    let prefix = '';
    if (this.contrat === 'PACIFICA RECOLTE CULTURE') {
      prefix = 'MC-';
    } else if (this.contrat === 'PACIFICA GRELE') {
      prefix = 'AC-';
    } else {
      throw new TerrexpertParseError(TerrexpertParseErrorType.INVALID_CONTRACT_TYPE);
    }
    this.pacificaContractNumber = prefix + this.numPolice;

    const matches = this.refCompagnie.match(/^(\d{10})\/(\d{4})$/);
    if (matches?.length !== 3) {
      throw new TerrexpertParseError(TerrexpertParseErrorType.INVALID_REF_COMPAGNIE);
    }
    this.pacificaDosclimNumber = matches[1];

    if (this.dateSinistre !== null && this.dateSinistre !== '') {
      if (!this.dateSinistre.match(/^\d{2}\/\d{2}\/\d{4}$/)) {
        throw new TerrexpertParseError(TerrexpertParseErrorType.INVALID_DATE_SINISTRE);
      }
    }

    this.lossId = null;
    this.cropId = null;
    this.farmId = null;
    this.policyId = null;
    this.claimId = null;
    this.harvestId = null;
    this.userExists = false;

    const year = parseInt(this.campagne);
    if (year && year >= 2016 && year <= 2035) {
      this.harvestYear = '' + year;
    } else {
      throw new TerrexpertParseError(TerrexpertParseErrorType.INVALID_HARVEST_YEAR);
    }
  }
}

export enum TerrexpertParseErrorType {
  INVALID_EMAIL,
  INVALID_POLICY_NUMBER,
  INVALID_DATE_SINISTRE,
  INVALID_HARVEST_YEAR,
  INVALID_CONTRACT_TYPE,
  INVALID_REF_COMPAGNIE,
  INVALID_LOSS,
  INVALID_HEADER,
  HARVEST_NOT_FOUND,
  USER_NOT_FOUND,
  FARM_NOT_FOUND,
  POLICY_NOT_FOUND,
  INVALID_CROP,
  NO_CSV_DATA_FOUND,
  NOT_ENOUGH_LINES,
  NOT_ENOUGH_FIELDS,
  UNKNOWN,
}

export class TerrexpertParseError extends ReportableError {
  rowNumber: number;
  error: TerrexpertParseErrorType;
  constructor(errorType: TerrexpertParseErrorType, rowNumber: number = 0, message?: string) {
    message ? super(message) : super(TerrexpertParseErrorType[errorType].toString() + ' at row ' + rowNumber);
    this.rowNumber = rowNumber;
    this.error = errorType;
  }
}
