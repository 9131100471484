import {getOverlapRatio} from '../geo';
import {ClaimCustomColumns, HarvestCustomColumns} from '../models/CustomColumns';
import {Claim, Farm, Field, Harvest} from '../models/interfaces';
import {HarvestKey, harvestAggregationKeyEq} from '../selectors/harvest-key';
import {filterNulls} from '../util/arr-util';
import {isSameOrMoreSpecificCropId} from '../util/harvest-util';
import {PostgrestQueryAnd} from '../util/postgrest-query';
import {ImportedData, ImportedFarm, ImportedField, ImportedHarvest} from './gt-pack';
import {ImportedClaim} from './gt-pack';
import {StateOrm} from './stateOrm';

// Some French customer use this 9x1 dummy telepac ids on different farms, which we should NOT dedupe.
export const NON_DEDUPED_TELEPAC_IDS: string[] = ['111111111', '999999999'];

interface DuplicatePolicyNumberWarning {
  type: 'DuplicatePolicyNumberWarning';
  policy_number: string;
}

interface DuplicateFarmReferenceWarning {
  type: 'DuplicateFarmReferenceWarning';
  external_farm_id: null | string;
  telepac_id: null | string;
}

interface DuplicateFieldReferenceWarning {
  type: 'DuplicateFieldReferenceWarning';
  external_farm_id: null | string;
  telepac_id: null | string;
  external_field_id: string;
}

interface DuplicateHarvestWarning {
  type: 'DuplicateHarvestWarning';
  field?: Field;
  harvest: Harvest;
}

interface DuplicateClaimWarning {
  type: 'DuplicateClaimWarning';
  claim_number: string;
  policy_number: string | null;
  claim_ids: string[];
}

export type MergeGtPackWarning =
  | DuplicateFarmReferenceWarning
  | DuplicateFieldReferenceWarning
  | DuplicateHarvestWarning
  | DuplicatePolicyNumberWarning
  | DuplicateClaimWarning;

export async function mergeImportedFarm(stateOrm: StateOrm, farm: ImportedFarm): Promise<MergeGtPackWarning[]> {
  let warnings: MergeGtPackWarning[] = [];
  if (NON_DEDUPED_TELEPAC_IDS.includes(farm.telepac_id!)) {
    return warnings;
  }
  if (!farm.farm_id && farm.external_farm_id) {
    const query: PostgrestQueryAnd = {
      and: [{column: 'external_farm_id', operator: 'eq', value: farm.external_farm_id}],
    };

    if (farm.user_group) {
      query.and.push({column: 'user_group', operator: 'eq', value: farm.user_group});
    }
    let sameFarms: Farm[] = await stateOrm.fetchEntitiesBy('farm', query);

    if (
      sameFarms.length == 1 &&
      (!sameFarms[0].telepac_id || !farm.telepac_id || sameFarms[0].telepac_id == farm.telepac_id)
    ) {
      farm.farm_id = sameFarms[0].farm_id;
    } else if (sameFarms.length > 1) {
      warnings.push({
        type: 'DuplicateFarmReferenceWarning',
        external_farm_id: farm.external_farm_id,
        telepac_id: farm.telepac_id,
      });
    }
  }

  // Make sure to use the same logic as dedupe.ts
  if (!farm.farm_id && farm.telepac_id) {
    const query: PostgrestQueryAnd = {
      and: [{column: 'telepac_id', operator: 'eq', value: farm.telepac_id}],
    };
    if (farm.user_group) {
      query.and.push({column: 'user_group', operator: 'eq', value: farm.user_group});
    }
    let sameFarms: Farm[] = await stateOrm.fetchEntitiesBy('farm', query);

    if (
      sameFarms.length == 1 &&
      (!sameFarms[0].external_farm_id ||
        !farm.external_farm_id ||
        sameFarms[0].external_farm_id == farm.external_farm_id)
    ) {
      farm.farm_id = sameFarms[0].farm_id;
    } else if (sameFarms.length > 1) {
      warnings.push({
        type: 'DuplicateFarmReferenceWarning',
        external_farm_id: farm.external_farm_id,
        telepac_id: farm.telepac_id,
      });
    }
  }

  if (!farm.farm_id) {
    // If this farm doesn't already exist, then it's fields cannot already exist. We do not attempt to merge
    // duplicate fields because external_field_ids are not unique (i.e. there may be multiple fields called i1,p1).
    return warnings;
  }

  // Merge policies
  if (!farm.policy?.policy_id && farm.policy?.policy_number?.length) {
    const existingFarm = await stateOrm.getFarmById(farm.farm_id);
    if (existingFarm) {
      // For now, we do it for the farm user_group only, ignoring transitive grantors.
      // TODO(savv): we should merge farm.policy_id using getMatchingPolicies. In order for this to work,
      //  the farm needs to already have a user group, which is currently not the case. Make sure not to match with
      //  empty policy numbers. This is only useful once we start importing arbitrary Geojson & GtPack files.
      // TODO(seb): Merge this with save.ts#findPolicy (which does not filter by user_group).
      const matchingPolicies = await stateOrm.fetchEntitiesBy('policy', {
        and: [
          {
            column: 'user_group',
            operator: 'eq',
            value: existingFarm.user_group,
          },
          {
            column: 'policy_number',
            operator: 'eq',
            value: farm.policy.policy_number,
          },
        ],
      });
      if (matchingPolicies.length > 1) {
        warnings.push({type: 'DuplicatePolicyNumberWarning', policy_number: farm.policy.policy_number});
      }
      if (matchingPolicies.length > 0) {
        farm.policy.policy_id = matchingPolicies[0].policy_id;
      }
    }
  }

  const compareHarvests = (h1: Harvest, h2: ImportedHarvest) => {
    if (h1.harvest_year != h2.harvest_year) {
      return false;
    }
    // If either harvest has Culture (=sheet name) then only merge them if they are the same.
    // That is, do not merge imported harvests with non-imported harvests.
    if (h1.metadata?.Culture || h2.metadata?.Culture) {
      return h1.metadata && h2.metadata && h1.metadata.Culture == h2.metadata.Culture;
    }

    // For farm harvests we only accept exact matches, because it's not uncommon for a farm to have
    // multiple harvests with the same crop_id in the same year.
    // TODO(savv): consider whether to ignore farm harvests with an external_harvest_id. For example,
    //  if there are multiple farm harvests for aoc-vully, we should prefer to merge with the one
    //  without an external_harvest_id. Currently, this doesn't have an impact.
    return harvestAggregationKeyEq(h1, h2);
  };
  const existingFarmHarvests = await stateOrm.getFarmHarvestsForFarm(farm.farm_id);
  for (const farmHarvest of farm.farmHarvests) {
    if (farmHarvest.harvest_id) {
      continue;
    }
    const matchingHarvests = existingFarmHarvests.filter(x => compareHarvests(x, farmHarvest));
    if (matchingHarvests.length == 1) {
      farmHarvest.harvest_id = matchingHarvests[0].harvest_id;
    }
  }

  const farmFields = await stateOrm.getFieldsByFarmId(farm.farm_id);
  for (const field of farm.fields) {
    const refFieldMatches = field.external_field_id
      ? farmFields.filter(x => field.external_field_id && x.external_field_id == field.external_field_id)
      : [];

    if (refFieldMatches.length === 1) {
      let ratio =
        field.field_shape &&
        refFieldMatches[0].field_shape &&
        getOverlapRatio(field.field_shape, refFieldMatches[0].field_shape);
      if (ratio == null) {
        ratio = 1; // Assume it's the same shape, unless proven otherwise.
      }

      if (ratio > 0.9) {
        field.field_id = refFieldMatches[0].field_id;
      } // else: inserting this as a new field.
    } else if (refFieldMatches.length > 1) {
      warnings.push({
        type: 'DuplicateFieldReferenceWarning',
        external_farm_id: farm.external_farm_id,
        telepac_id: farm.telepac_id,
        external_field_id: field.external_field_id ?? '',
      });
    } else if (field.field_shape) {
      const shapeMatches = farmFields.filter(x => {
        let ratio = x.field_shape && getOverlapRatio(field.field_shape!, x.field_shape);
        if (ratio == null) {
          ratio = 0; // Assume no overlap unless we can prove otherwise.
        }
        return ratio > 0.9;
      });

      if (shapeMatches.length == 1) {
        field.field_id = shapeMatches[0].field_id; // update shape, but otherwise dedupe this field
      }
    }

    // Else: this is a new field that will be inserted under farm_id.
  }

  const harvests = await stateOrm.fetchEntitiesBy('harvest', {
    column: 'field_id',
    operator: 'in',
    value: filterNulls(farm.fields.map(x => x.field_id)),
  });
  for (const importedField of farm.fields) {
    const fieldHarvests = importedField.field_id ? harvests.filter(x => x.field_id == importedField.field_id) : [];
    for (const importedFieldHarvest of importedField.harvests) {
      if (importedFieldHarvest.harvest_id) {
        continue;
      }
      const dupeHarvests = fieldHarvests
        .filter(fieldHarvest => harvestSimilarity(fieldHarvest, importedFieldHarvest) > 0)
        .sort((a, b) => harvestSimilarity(b, importedFieldHarvest) - harvestSimilarity(a, importedFieldHarvest));
      if (dupeHarvests.length) {
        importedFieldHarvest.harvest_id = dupeHarvests[0].harvest_id;
        const refFieldMatches = farmFields.filter(x => x.field_id == importedField.field_id);
        warnings.push({type: 'DuplicateHarvestWarning', field: refFieldMatches[0], harvest: dupeHarvests[0]});
      }

      // This logic is like consolidateFieldHarvests, but for imported harvests.
      const matchingFarmHarvests = getMatchingFarmHarvests(existingFarmHarvests, importedFieldHarvest);
      if (matchingFarmHarvests.length == 1) {
        upgradeHarvest(importedFieldHarvest, matchingFarmHarvests[0]);
      }
    }
  }

  // Only dedupe visit/claim if we have a claim number.
  // TODO(seb): Consider how/if to dedupe visits without claim numbers.
  if (farm.claim?.claim_number) {
    const claims = await stateOrm.fetchEntitiesBy('claim', {
      and: [
        {
          column: 'farm_id',
          operator: 'eq',
          value: farm.farm_id,
        },
        {
          column: 'external_claim_id',
          operator: 'eq',
          value: farm.claim?.claim_number,
        },
      ],
    });

    // There could be multiple claims for the same claim_number, but with different policies.
    const matchingClaims = claims.filter(
      c => c.external_claim_id == farm.claim?.claim_number && c.policy_id == farm.policy?.policy_id,
    );

    if (matchingClaims.length > 1) {
      warnings.push({
        type: 'DuplicateClaimWarning',
        claim_number: farm.claim.claim_number,
        claim_ids: matchingClaims.map(c => c.claim_id),
        policy_number: farm.policy?.policy_number ?? null,
      });
    }
    if (matchingClaims.length > 0) {
      farm.claim.claim_id = matchingClaims[0].claim_id;

      // Merge Groupama comments
      mergeClaimCustomColumns(farm.claim, matchingClaims[0]);
    }
  }

  await consolidateExistingFieldHarvests(stateOrm, farm);

  return warnings;
}

type HarvestWithCustomColumns = HarvestKey & Pick<Harvest, 'irrigated' | 'organic'> & {custom_columns?: any};

// The similarity between two harvests:
// - the harvest year must be the same, else we return -1
// - if the crop_id is the same or more specific => 1 point
// - for each other detail that is the same => 1 more point
function harvestSimilarity(h1: HarvestWithCustomColumns, h2: HarvestWithCustomColumns): number {
  if (h1.harvest_year != h2.harvest_year) {
    return -1;
  }
  const {telepacData: telepacData1} = new HarvestCustomColumns(h1.custom_columns);
  const {telepacData: telepacData2} = new HarvestCustomColumns(h2.custom_columns);
  let telepacScore = 0;
  if (telepacData1 && telepacData2) {
    telepacScore += telepacData1.codeCulture == telepacData2.codeCulture ? 1 : 0;
    telepacScore += telepacData1.precision == telepacData2.precision ? 1 : 0;
    telepacScore += telepacData1.semences == telepacData2.semences ? 1 : 0;
  }
  let keyScore = 0;
  if (isSameOrMoreSpecificCropId(h1.crop_id, h2.crop_id)) {
    keyScore +=
      1 +
      (h1.irrigated == h2.irrigated ? 1 : 0) +
      (h1.organic == h2.organic ? 1 : 0) +
      (h1.aux_key == h2.aux_key ? 1 : 0);
  }
  return Math.max(telepacScore, keyScore);
}

export async function dedupeImportedData(email: string, stateOrm: StateOrm, data: ImportedData) {
  let warnings: MergeGtPackWarning[] = [];
  for (const farm of data.farms) {
    warnings.push(...(await mergeImportedFarm(stateOrm, farm)));
    if (!farm.farm_id) {
      if (!email.endsWith('@green-triangle.com')) {
        farm.editors.push('@' + email);
      }
    }
  }

  return warnings;
}

// If this farm has field harvests with matching crop_id & year, we update their irrigated, organic, & variety columns,
// so that they appear as one.
// TODO(savv): consider moving this functionality inside of mergeImportedFarm in case there are other cases
//  where the farm harvests are definitive; or in case we refactor our codebase as such.
export async function consolidateExistingFieldHarvests(stateOrm: StateOrm, farm: ImportedFarm): Promise<void> {
  const fields = await stateOrm.fetchEntitiesBy('field', {column: 'farm_id', operator: 'eq', value: farm.farm_id});
  const existingFieldHarvests = await stateOrm.fetchEntitiesBy('harvest', {
    column: 'field_id',
    operator: 'in',
    value: fields.map(x => x.field_id),
  });
  for (const existingFieldHarvest of existingFieldHarvests) {
    const matchingFarmHarvests = getMatchingFarmHarvests(farm.farmHarvests, existingFieldHarvest);
    if (matchingFarmHarvests.length == 1) {
      let field = farm.fields.find(x => x.field_id == existingFieldHarvest.field_id);
      if (!field) {
        field = new ImportedField({field_id: existingFieldHarvest.field_id});
        farm.fields.push(field);
      }
      let harvest = field.harvests.find(x => x.harvest_id == existingFieldHarvest.harvest_id);
      if (!harvest) {
        harvest = new ImportedHarvest({harvest_id: existingFieldHarvest.harvest_id});
        field.harvests.push(harvest);
      }
      upgradeHarvest(harvest, matchingFarmHarvests[0]);
    }
  }
}

function getMatchingFarmHarvests(farmHarvests: HarvestWithCustomColumns[], fieldHarvest: HarvestWithCustomColumns) {
  const matchingFarmHarvests = farmHarvests.filter(
    existingFarmHarvest => harvestSimilarity(existingFarmHarvest, fieldHarvest) > 0,
  );
  return matchingFarmHarvests.length > 1
    ? farmHarvests.filter(x => harvestSimilarity(x, fieldHarvest) > 1)
    : matchingFarmHarvests;
}

function upgradeHarvest(
  fieldHarvest: HarvestKey & Pick<Harvest, 'irrigated' | 'organic'>,
  farmHarvest: HarvestKey & Pick<Harvest, 'irrigated' | 'organic'>,
) {
  fieldHarvest.crop_id = farmHarvest.crop_id;
  fieldHarvest.irrigated = farmHarvest.irrigated;
  fieldHarvest.organic = farmHarvest.organic;
  fieldHarvest.aux_key = farmHarvest.aux_key;
}

function mergeClaimCustomColumns(importedClaim: ImportedClaim, previousClaim: Claim) {
  const comments = (previousClaim.custom_columns as ClaimCustomColumns)?.grpm?.comments;
  const newGrpmComments = (importedClaim.custom_columns as ClaimCustomColumns)?.grpm?.comments;
  if (comments && newGrpmComments && comments.length > 0) {
    newGrpmComments.unshift(...comments);
  }
}
