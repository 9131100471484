import {FormyErrors} from '../../../src/Formy';
import {ImportedData, ImportedFarm, ImportedField, ImportedHarvest} from '../../../src/gt-pack/gt-pack';
import {IndexedCrops} from '../../../src/redux/reducers/crops';
import {
  isAreaValue,
  isArr,
  isGeojsonPolygon,
  isHarvestYear,
  isLocation,
  isStr,
  isUuid,
} from '../../../src/validator-constraints';

export function validateImportedHarvest(
  crops: IndexedCrops,
  x: Pick<ImportedHarvest, 'crop_id' | 'harvest_year'>,
): FormyErrors<ImportedHarvest> {
  return {
    crop_id: !x.crop_id,
    harvest_year: !x.harvest_year || !isHarvestYear(x.harvest_year),
  };
}

export function validateImportedField(crops: IndexedCrops, x: ImportedField): FormyErrors<ImportedField> {
  if (x.field_id) {
    return {field_id: !isUuid(x.field_id)};
  }

  return {
    external_field_id: !isStr(x.external_field_id, 1),
    field_shape: !isGeojsonPolygon(x.field_shape),
    field_location: !isLocation(x.field_location),
    field_area: !isAreaValue(x.field_area),
    harvests: !isArr(x.harvests) || x.harvests.map(x => validateImportedHarvest(crops, x)),
  };
}

export function validateImportedFarm(crops: IndexedCrops, x: ImportedFarm): FormyErrors<ImportedFarm> {
  if (x.farm_id) {
    return {farm_id: !isUuid(x.farm_id)};
  }

  const telepacAndExternalIdMissing = !isStr(x.external_farm_id, 2) && !isStr(x.telepac_id, 9);
  return {
    external_farm_id: telepacAndExternalIdMissing,
    telepac_id: telepacAndExternalIdMissing,
    farm_name: !isStr(x.farm_name, 3),
    address: !isStr(x.address, 3),
    fields: !isArr(x.fields) || x.fields.map(x => validateImportedField(crops, x)),
    policy:
      x.policy === null
        ? false
        : {
            policy_number: x.policy.policy_number == null ? false : !isStr(x.policy.policy_number, 3),
            policy_id: !isUuid(x.policy.policy_id),
          },
    user_group: !isStr(x.user_group, 1),
  };
}

export function validateImportedData(crops: IndexedCrops, x: ImportedData): FormyErrors<ImportedData> {
  return {
    farms: !isArr(x.farms) || x.farms.map(x => validateImportedFarm(crops, x)),
  };
}
