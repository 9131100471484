import React, {useContext} from 'react';
import {useSelector} from 'react-redux';
import {CROP_COLORS} from '../../../src/constants/colors';
import {GetStatsByHarvestRow} from '../../../src/models/interfaces';
import {HarvestDesc} from '../../../src/report/report-types';
import {getBaseCrop, getCrops} from '../../../src/selectors/crops';
import {getCountryCodeGroups} from '../../../src/selectors/units';
import {cropDesc, harvestDesc} from '../../../src/text/desc';
import {filterNulls} from '../../../src/util/arr-util';
import {Apis} from '../apis/Apis';
import {ApisContext} from '../apis/ApisContext';
import BarChart from '../report/BarChart';
import {useIsVisible} from '../util/hooks';
import {DashboardCard} from './DashboardCard';
import {useStatsByHarvest} from './queries';

// A convenience function that consistently aggregates and labels stats by harvest, using fn.
export function groupByCrop(
  apis: Apis,
  data: GetStatsByHarvestRow[],
  fn: (x: GetStatsByHarvestRow) => null | number,
): [GetStatsByHarvestRow, string, number][] {
  const {t, store} = apis;
  const crops = getCrops(apis.store.getState());
  const harvestYears = new Set(filterNulls(data.map(x => x.harvest_year)));
  const conciseHarvestDesc = (harvest: HarvestDesc): string =>
    harvestYears.size == 1
      ? cropDesc(t, crops, harvest.crop_id)
      : harvestDesc(t, crops, harvest, null, getCountryCodeGroups(store.getState()));
  return data.map(
    x =>
      // TODO(savv): use full HarvestKey here, once it's returned by the GetStatsByHarvest API.
      [
        x,
        conciseHarvestDesc({
          crop_id: x.crop_id ?? 'unknown',
          harvest_year: x.harvest_year,
          aux_key: null,
        }),
        fn(x),
      ] as [GetStatsByHarvestRow, string, number],
  );
}

export const SamplesByCrop: React.FC = () => {
  const apis = useContext(ApisContext),
    crops = useSelector(getCrops);
  const ref = React.useRef(null);
  const enabled = useIsVisible(ref);
  const {data = [], isFetching} = useStatsByHarvest({enabled});
  const sampleCountByCropType = groupByCrop(apis, data, x => x.num_samples)
    .filter(([, , value]) => {
      return value !== null;
    })
    .map(([statsByHarvest, key, value]) => ({
      color: CROP_COLORS[getBaseCrop(crops, statsByHarvest.crop_id) ?? 'unknown'],
      key,
      value,
    }))
    .sort((a, b) => b.value - a.value)
    .slice(0, 7);

  return (
    <div ref={ref}>
      <DashboardCard
        long
        title={apis.t('SampleCropTitle')}
        loading={isFetching}
        hasData={sampleCountByCropType.length > 0}>
        <BarChart
          width={300}
          height={300}
          id="sample-crop-chart"
          data={sampleCountByCropType ?? []}
          formatValue={String}
        />
      </DashboardCard>
    </div>
  );
};
